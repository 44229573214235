import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled, { keyframes } from 'styled-components';

import colors from 'Static/typography/colors';

const coreLeadingDotsAnimation = keyframes`
	0% {opacity: 0.4; transform: scale(1, 1);} 
	50% {opacity: 1; transform: scale(1.2, 1.2);} 
	100% {opacity: 0.4; transform: scale(1, 1);}
`;

const LoadingDotsStyled = styled.div`
	&.core-loading-dots {
		display: flex;
		align-items: center;
		text-align: center;
		width: 100%;
		margin: 3.125rem 0;

		.dot {
			animation: ${coreLeadingDotsAnimation} 1.5s infinite ease-in-out;
			background-color: ${colors.PURPS};
			border-radius: 8px;
			display: inline-block;
			height: 8px;
			width: 8px;
			margin-right: 4px;

			&:nth-child(2) {
				animation-delay: 0.5s;
			}

			&:nth-child(3) {
				animation-delay: 1s;
			}
		}
	}
`;

type OwnProps = {
	className?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LoadingDots.defaultProps;

// @ts-expect-error TS(7022): 'LoadingDots' implicitly has type 'any' because it... Remove this comment to see the full error message
const LoadingDots = (props: Props) => {
	const { className, ...otherProps } = props;
	return (
		<LoadingDotsStyled
			key='loading-dots'
			{...otherProps}
			className={`core-loading-dots ${className}`}
		>
			<div className='dot' key='dot-1' />
			<div className='dot' key='dot-2' />
			<div className='dot' key='dot-3' />
		</LoadingDotsStyled>
	);
};

LoadingDots.defaultProps = {
	className: '',
};

export default LoadingDots;
